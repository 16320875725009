import AdminTransactionDetailsLayout from "@Organism/Admin/Transaction/Details";
import { _fetchTransactionById } from "@Services/transaction";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export function loader({ params }) {
  const { id } = params;

  const getTransactionDetail = _fetchTransactionById(id);

  return defer({
    getTransactionDetail,
  });
}

export default function TransactionDetailPage() {
  const { getTransactionDetail } = useLoaderData();

  return (
    <Suspense
      fallback={<AdminTransactionDetailsLayout data={null} isLoading />}
    >
      <Await
        resolve={getTransactionDetail}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <AdminTransactionDetailsLayout data={value.data} />}
      </Await>
    </Suspense>
  );
}
