import CheckoutCourseLayout from "@Organism/Checkout";
import { getCourse } from "@Services/course";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  const { id } = params;
  return defer({
    packageCourseDetails: getCourse(id, true),
  });
}
export default function CheckoutPage() {
  const { packageCourseDetails } = useLoaderData();

  return (
    <Suspense fallback={<CheckoutCourseLayout isLoading />}>
      <Await resolve={packageCourseDetails} errorElement={<div>error</div>}>
        {(packageCourseDetails) => (
          <CheckoutCourseLayout data={packageCourseDetails?.data} />
        )}
      </Await>
    </Suspense>
  );
}
