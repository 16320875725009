import Button from "@Atom/Button";
import Card from "@Atom/Card";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Icon from "@Atom/Icon";
import ScheduleCard from "@Atom/ScheduleCard";
import { priceFormat } from "@Helpers/priceFormat";
import FooterLandingPage from "@Molecule/FooterLandingPage";
import InputField from "@Molecule/InputField";
import SyllabusCard from "@Molecule/SyllabusCard";
import ViewFileModal from "@Molecule/ViewFileModal";
import Images from "@Theme/Images";
import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import { exchangeVoucher } from "@Services/voucher";
import Modal from "@Atom/Modal";
import { useNavigate, useParams } from "react-router-dom";
import useWindowSize from "@Hooks/useWindowSize";
import RadioButton from "@Atom/RadioButton";
import moment from "moment";
import { translateError } from "@Helpers/translateError";
import ModalError from "@Molecule/ModalError";
import useQuery from "@Hooks/useQuery";
import { decryptStorageData } from "@Helpers/encryptDecrypt";

export default function LandingClassDetailsLayout({ isLoading = false, data }) {
  // const { pathname, hash: h } = useLocation()
  const [showPreview, setShowPreview] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [successExchangeModal, setSuccessExchangeModal] = useState(false);
  const [errorRedeem, setErrorRedeem] = useState("");

  const [hash, setHash] = useState("");
  const [loading, setLoading] = useState(false);

  const scrolledRef = useRef(false);
  const hashRef = useRef(hash);
  const navigate = useNavigate();
  const query = useQuery();
  const voucherQuery = query.get("voucher") || "";

  const { width } = useWindowSize();
  const [selectedBatch, setSelectedBatch] = useState(0);
  const [openSelectBatch, setOpenSelectBatch] = useState(false);
  const { id } = useParams();
  const user = decryptStorageData("user", localStorage);

  useEffect(() => {
    setVoucherCode(voucherQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hash) {
      // We want to reset if the hash has changed
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }

      // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
      if (!scrolledRef.current) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          scrolledRef.current = true;
        }
      }
    }
  }, [hash, hashRef]);

  const competencesPerAspectType = useMemo(() => {
    const allCompetences = data?.competences;
    const arr = allCompetences?.map((obj) => obj?.aspect);
    const types = arr?.filter((item, index) => arr.indexOf(item) === index);

    let perType = types?.map((type) => {
      return {
        type: type,
        competences: [],
      };
    });

    for (let i = 0; i < perType?.length; i++) {
      for (let j = 0; j < allCompetences?.length; j++) {
        const competence = allCompetences[j];
        if (perType[i]?.type === competence?.aspect) {
          perType[i]?.competences?.push(competence?.type);
        }
      }
    }

    return perType;
  }, [data?.competences]);

  const handleHover = (type) => {
    const element = document.getElementById("playButton");
    if (type === "enter") {
      element.classList.remove(Styles.move);
    } else {
      element.classList.add(Styles.move);
    }
  };

  const handleExchangeVoucher = async () => {
    const dataToSend = {
      voucherCode: voucherCode,
      method: "PRAKERJA",
      batch: selectedBatch,
    };
    try {
      setLoading(true);
      const res = await exchangeVoucher(dataToSend);
      setOpenSelectBatch(false);
      setLoading(false);

      if (res.status) {
        setSuccessExchangeModal(true);
      }
    } catch (error) {
      setLoading(false);
      setOpenSelectBatch(0);
      setErrorRedeem(translateError(error.response.data.error));
      setSelectedBatch(0);
    }
  };

  useEffect(() => {
    if (voucherCode) {
      setErrorRedeem(false);
    }
  }, [voucherCode]);

  const filteredBatch = useMemo(() => {
    const temp = { ...data };
    return temp?.batches?.filter(
      (batch) => moment(new Date()).format("YYYY-MM-DD") <= batch.startDate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckoutClass = () => {
    if (user) {
      navigate(`/checkout/${id}`);
    } else {
      navigate(`/login?c=/checkout/${id}`);
    }
  };
  return (
    <div className={Styles.container}>
      <DoodleGradientCard
        className={Styles.header}
        doodleOpacity=".3"
        doodleScale="1"
      >
        {width > 768 && (
          <img className={Styles.clay} src={Images.LEFT_CLAY} alt="" />
        )}
        <div className={Styles.preview}>
          <img src={Images.MAC} alt="" />

          {data?.asset?.introduction?.videoURL && (
            <button
              className={`${Styles.playButton} ${Styles.move}`}
              id="playButton"
              onMouseEnter={() => handleHover("enter")}
              onMouseLeave={() => handleHover("leave")}
              onClick={() => setShowPreview(true)}
            >
              <Icon icon={"play"} size={24} />
            </button>
          )}

          <div className={Styles.box}>
            {/* <img src={fileBaseUrl + data?.asset?.thumbnail?.imageURL} alt='' /> */}
            <video src={data?.asset?.introduction?.videoURL} />
          </div>
        </div>
        <div className={Styles.title}>
          <h3>{data?.title}</h3>
          <div className={Styles.label}>
            <div>
              <Icon icon={"star-rating"} size={20} color="#FFCF40" />
              <span>Belum ada penilaian</span>
            </div>
            <div>
              <Icon icon={"people"} size={16} />
              <span>0 peserta</span>
            </div>
            {!!data?.batches?.length && (
              <div>
                <Icon icon={"calendar"} size={16} />
                <span>{data?.batches?.length} Jadwal Tersedia</span>
              </div>
            )}
          </div>
          <span>{`Rp${priceFormat(data?.price?.base)}`}</span>
          <div className={Styles.actions}>
            {data?.participantType !== "PRAKERJA" ? (
              <button
                className={Styles.headerButton}
                onClick={() => handleCheckoutClass()}
              >
                <span>Beli Sekarang</span>
                <Icon icon={"arrow-up-right"} size={16} />
              </button>
            ) : (
              <div className={Styles.voucherBox}>
                <div
                  className={Styles.inputBox}
                  is-error={errorRedeem ? "true" : "false"}
                >
                  <InputField
                    title={"Punya kode voucher? tukarkan disini"}
                    placeholder="Masukkan voucher Prakerja"
                    value={voucherCode}
                    setValue={setVoucherCode}
                    titleColor="#fff"
                  />
                  {errorRedeem && (
                    <span className={Styles.errorMessage}>{errorRedeem}</span>
                  )}
                </div>
                <button
                  className={`${Styles.headerButton} ${Styles.v2}`}
                  onClick={() =>
                    data.platform === "VIDEO"
                      ? handleExchangeVoucher()
                      : setOpenSelectBatch(true)
                  }
                >
                  <span>Tukar</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </DoodleGradientCard>
      <div className={Styles.content}>
        {width > 768 && (
          <div className={Styles.left}>
            <Card className={Styles.card}>
              <h5>Daftar Isi</h5>
              <div className={Styles.list}>
                {[
                  {
                    title: "Deskripsi Kelas",
                    id: "description",
                  },
                  // {
                  //   title: 'Testimoni',
                  //   id: 'testimony'
                  // }
                  {
                    title: "Pengajar",
                    id: "instructor",
                    // ref: instructorRef
                  },
                  {
                    title: "Tujuan Umum",
                    id: "generalPurpose",
                  },
                  {
                    title: "Tujuan Khusus",
                    id: "specialPurpose",
                  },
                  {
                    title: "Aspek Kompetensi",
                    id: "competencyAspect",
                  },
                  {
                    title: "Sasaran Peserta",
                    id: "targetParticipants",
                  },
                  {
                    title: "Jadwal Kelas",
                    id: "schedules",
                  },
                  {
                    title: "Silabus",
                    id: "sessions",
                  },
                ]
                  ?.filter((obj) =>
                    data?.batches?.length ? obj : obj?.id !== "schedules"
                  )
                  ?.map((t, tI) => (
                    <div
                      key={tI}
                      // to={`${pathname}#${t?.id}`}
                      onClick={() => setHash(t?.id)}
                      className={`${Styles.linkTo}
                       ${
                         hash === `${t?.id}` ||
                         (!hash && t?.id === "description")
                           ? //  || backupHash === `#${t?.id}`
                             Styles.active
                           : ""
                       }
                       `}
                    >
                      {(hash === `${t?.id}` ||
                        (!hash && t?.id === "description")) && (
                        // || backupHash === `#${t?.id}`
                        <div className={Styles.bullet} />
                      )}
                      <span>{t?.title}</span>
                    </div>
                  ))}
              </div>
              {
                <div className={Styles.button}>
                  <Button
                    text={"Beli Sekarang"}
                    endIcon={"arrow-up-right"}
                    // disabled
                    onClick={() => handleCheckoutClass()}
                  />
                </div>
              }
            </Card>
          </div>
        )}

        <div className={Styles.right}>
          <Section
            id="description"
            title="Deskripsi Kelas"
            content={
              <div className={Styles.text}>{data?.description || "-"}</div>
            }
          />
          {/* <Section
            title='Testimoni Alumni'
            content={
              <div className={Styles.testimoniesWrapper}>

                {data?.overview?.descriptionHTML || 'tidak ada deskripsi'}
              </div>
            }
          /> */}
          <Section
            id="instructor"
            title="Pengajar"
            content={
              <div className={Styles.instructor}>
                {data?.instructor?.profilePicURL && (
                  <div className={Styles.iImg}>
                    <img
                      className={Styles.picture}
                      src={data?.instructor?.profilePicURL}
                      alt=""
                    />
                    <img
                      className={Styles.bg}
                      src={Images.INSTRUCTOR_BG}
                      alt=""
                    />
                  </div>
                )}

                <div className={Styles.iDesc}>
                  <span>Tentang</span>
                  <h5>{data?.instructor?.name}</h5>
                  <p
                    className={`${Styles.text} ${Styles.v2}`}
                    style={{ marginTop: 24 }}
                  >
                    {data?.instructor.bio}
                  </p>
                </div>
              </div>
            }
          />
          <Section
            id="generalPurpose"
            title="Tujuan Umum"
            content={
              <div className={Styles.text}>{data?.generalPurposes || "-"}</div>
            }
          />
          <Section
            id="specialPurpose"
            title="Tujuan Khusus"
            content={
              <div className={Styles.text}>
                {data?.specificPurposes?.map((p, pI) => (
                  <ListText key={pI} text={p?.aspect} />
                ))}
              </div>
            }
          />
          {competencesPerAspectType?.length > 0 && (
            <Section
              id="competencyAspect"
              title="Aspek Kompetensi"
              content={
                <div className={`${Styles.text} ${Styles.allAspects}`}>
                  {competencesPerAspectType?.map((p, pI) => (
                    <div key={pI} className={Styles.perAspect}>
                      <div className={Styles.cTitle}>
                        <span>{pI + 1}.</span>
                        <span>{p?.type}</span>
                      </div>
                      <div className={Styles.cList}>
                        {p?.competences?.map((c, cI) => (
                          <ListText key={cI} text={c} />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          )}
          <Section
            id="targetParticipants"
            title="Sasaran Peserta"
            content={
              <div className={Styles.text}>
                {data?.targetParticipants?.map((p, pI) => (
                  <ListText key={pI} text={p?.aspect} />
                ))}
              </div>
            }
          />
          {data?.platform !== "VIDEO" && !!data?.batches?.length && (
            <Section
              id="schedules"
              title="Jadwal Kelas"
              content={
                <div className={Styles.scheduleWrapper}>
                  {data?.batches?.map((b, bI) => (
                    <ScheduleCard
                      key={bI}
                      // title='eee'
                      startDate={b?.startDate}
                      endDate={b?.endDate}
                      startTime={b?.startTime || ""}
                      endTime={b?.endTime || ""}
                      dateAsTitle
                      extra={
                        moment(new Date()).format("YYYY-MM-DD") <=
                          b.startDate && "Kuota masih ada"
                      }
                    />
                  ))}
                </div>
              }
            />
          )}

          <Section
            id="sessions"
            title="Silabus"
            content={
              <div className={Styles.syllabus}>
                {data?.syllabus?.map((s, sI) => (
                  <SyllabusCard key={sI} data={s} sessionNumber={sI + 1} />
                ))}
              </div>
            }
          />
        </div>
      </div>
      <FooterLandingPage />

      {showPreview && (
        <ViewFileModal
          show={showPreview}
          file={data?.asset?.introduction?.videoURL}
          fileType={"video"}
          fileName={data?.title}
          onClose={() => setShowPreview(false)}
          isURL
        />
      )}
      <Modal isOpen={successExchangeModal}>
        <div className={Styles.modalSuccessContainer}>
          <p className={Styles.modalTitle}>Kelas Anda berhasil di aktifkan</p>
          <img alt="success" src={Images.SUCCESS_COIN} />
          <p className={Styles.modalDesc}>
            Ayo tingkatkan skillsmu dan mulai belajar bersama Skills.id
          </p>
          <Button
            text={"Mulai Belajar"}
            onClick={() => navigate("/student/")}
          />
        </div>
      </Modal>
      <Modal isOpen={openSelectBatch}>
        {filteredBatch?.length > 0 ? (
          <div className={Styles.modalSelectBatchContainer}>
            <div className={Styles.batchList}>
              <p>Pilih jadwal kelas anda</p>
              <div className={Styles.batchesList}>
                {filteredBatch.map((batch, idx) => (
                  <div className={Styles.batchWrapper} key={idx}>
                    <RadioButton
                      onClick={() => setSelectedBatch(batch.num)}
                      isSelected={selectedBatch === batch.num}
                    />
                    <p>
                      {moment(batch.startDate.split(",")[0]).format("Do MMMM")}{" "}
                      -{" "}
                      {moment(batch.endDate.split(",")[0]).format(
                        "Do MMMM YYYY"
                      )}
                    </p>
                  </div>
                ))}
              </div>
              <div className={Styles.selectBatchModalAction}>
                <Button
                  variant="outlined"
                  text={"Kembali"}
                  onClick={() => {
                    setSelectedBatch(0);
                    setOpenSelectBatch(0);
                  }}
                />
                <Button
                  text={"Submit"}
                  disabled={loading || selectedBatch === 0}
                  onClick={() => handleExchangeVoucher()}
                />
              </div>
            </div>
          </div>
        ) : (
          <ModalError
            title={"Jadwal Kelas Tidak Tersedia"}
            onClose={() => {
              setSelectedBatch(0);
              setOpenSelectBatch(0);
            }}
            content={
              <div className={Styles.noScheduleContainer}>
                <p>
                  Untuk sekarang ini tidak ada jadwal tersedia untuk kamu ikuti,
                  silahkan hubungi admin kami bila kamu memiliki pertanyaan
                  lainnya.
                </p>
                <p>
                  <span
                    onClick={() =>
                      window.open(
                        "https://api.whatsapp.com/send/?phone=6285925338608&text&type=phone_number&app_absent=0"
                      )
                    }
                  >
                    Klik disini
                  </span>{" "}
                  untuk menghubungi tim skills.id
                </p>
              </div>
            }
          />
        )}
      </Modal>
    </div>
  );
}

const Section = ({ title = "", content = <></>, ...props }) => {
  return (
    <div {...props} className={Styles.section}>
      <SectionTitle title={title} />
      <div className={Styles.sectionContent}>{content}</div>
    </div>
  );
};

const SectionTitle = ({ title = "" }) => {
  return (
    <div className={Styles.sectionTitle}>
      <div className={Styles.bullet} />
      <h4>{title}</h4>
    </div>
  );
};

const ListText = ({ text = "", noBulletPoint }) => {
  return (
    <div className={Styles.listText}>
      {!noBulletPoint && (
        <Icon icon={"half-eye"} size={"12px"} color={"#9360A8"} />
      )}
      <span className={Styles.text}>{text}</span>
    </div>
  );
};
