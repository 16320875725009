import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import Images from "@Theme/Images";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Styles from "./style.module.scss";
import useOutsideClick from "@Hooks/useOutsideClick";
import useWindowSize from "@Hooks/useWindowSize";
import moment from "moment";
import { checkPrakerjaStatus } from "@Services/prakerja";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import ReedemCodeModal from "@Molecule/ReedemCodeModal";
// import { addLeadingZero } from "@Helpers/addLeadingZero";

export default function ClassesSidebar({ userSheet, data }) {
  // const [progress, setProgress] = useState("50%");
  const { materialCode } = useParams();
  const { pathname } = useLocation();
  const path = pathname.split("/").pop();
  const { width } = useWindowSize();
  const chapterStr = path.split("-")[0];
  const chapterNumber = chapterStr.split("chapter")[1];
  const [openModal, setOpenModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const dataPrakerja = JSON.parse(localStorage.getItem("prakerjaPayload"));
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();

  const [sidebarData, setSidebarData] = useState([]);

  const progress = useMemo(() => {
    let percentage = Math.floor(
      (userSheet?.progress?.subjectsFinishedTotal /
        userSheet?.progress?.subjectsTotal) *
        100
    );
    if (percentage > 100) {
      percentage = 100;
    }
    return percentage + "%";
  }, [
    userSheet?.progress?.subjectsFinishedTotal,
    userSheet?.progress?.subjectsTotal,
  ]);

  const defaultSideBar = useMemo(() => {
    const activeSessionWebinar = (sIndex) => {
      if (user?.isAssesor) {
        return true;
      }
      if (sIndex === 0) {
        return userSheet.data.score.preTest >= 0;
      }
      if (sIndex > 0) {
        if (
          userSheet?.data?.sessions[sIndex - 1]?.subjects
            .filter((el) => el.type !== "TASK")
            .some((el) => !el.isComplete)
        ) {
          return false;
        }
      }
      if (
        moment(new Date()).format("YYYY-MM-DD") <
        userSheet.classSchedule[sIndex]?.date
      ) {
        return false;
      } else if (
        moment(new Date()).format("YYYY-MM-DD") ===
        userSheet.classSchedule[sIndex]?.date
      ) {
        if (
          userSheet.classSchedule[0]?.startHour <=
          new Date().getHours() + 1 + ":" + new Date().getMinutes()
        ) {
          return true;
        } else {
          return false;
        }
      }
      //  else {
      //   console.log("masuk", sIndex);
      //   return false;
      // }
      return true;
    };

    const activeSessionVideo = (sIndex) => {
      if (user?.isAssesor) {
        return true;
      }
      if (sIndex === 0) {
        return !!userSheet.data.score.preTest;
      }
      if (sIndex > 0) {
        if (!userSheet.data.sessions[sIndex - 1].completionDate) {
          return false;
        }

        if (
          userSheet.data.sessions[sIndex - 1].completionDate &&
          +moment(userSheet.data.sessions[sIndex - 1].completionDate).format(
            "Do"
          ) >= +moment().format("Do")
        ) {
          return false;
        }
      }

      return true;
    };

    const activeSessionWorkshop = (index) => {
      if (new Date(userSheet.classSchedule[0].date) <= new Date()) {
        console.log('masuk');
        if (
          userSheet.classSchedule[0]?.startHour <=
          new Date().getHours() + ":" + new Date().getMinutes()
        )
          return true;
      }
      return false;
    };

    const finishedAllSession = () => {
      const arr = [];
      if (user?.isAssesor) {
        return true;
      }
      for (let i = 0; i < userSheet.data.sessions.length; i++) {
        const session = userSheet.data.sessions[i].subjects;
        for (let j = 0; j < session.length; j++) {
          const subject = session[j];
          if (subject.type !== "TASK") {
            arr.push(subject);
          }
          if (subject.type === "TASK") {
            arr.push({
              ...subject,
              isComplete: user?.isAssesor ? true : subject.score.overall > 0,
            });
          }
        }
      }
      return arr.every((el) => el.isComplete === true);
    };
    const withPretest =
      data.configurations.find((el) => el.label === "preTest")?.value || true;
    const withPostTest =
      data.configurations.find((el) => el.label === "postTest")?.value || true;
    const withSkillTest =
      data.configurations.find((el) => el.label === "skillTest")?.value || true;

    switch (data?.platform) {
      case "OFFLINE":
        return [
          {
            title: "Class Introduction",
            child: [
              {
                title: "Aturan Kelas",
                icon: "rule",
                active: true,
                code: `rules?t=${data.title}`,
              },
              {
                title: "Jadwal Kelas",
                icon: "calendar-outlined",
                active: true,
                code: `schedules?t=${data.title}`,
              },
              {
                title: "Pretest",
                icon: "list_alt",
                active: true,
                code: `pretest?t=${data.title}`,
                hidden : !!withPretest

              },
            ],
          },
          {
            title: "Sesi & materi kelas",
            child: data.sessions.map((c, sIndex) => ({
              title: c.title,
              icon: "folder",
              active:
                (userSheet.data.sessions[sIndex].status !== "CLOSED" &&
                  userSheet.data.score.preTest >= 0) ||
                user?.isAssesor,
              showned: pathname?.includes(`chapter${sIndex + 1}`)
                ? true
                : false,
              subChild: c?.subjects?.map((subChild, mIndex) => ({
                type: subChild.type,
                title:
                  subChild.type !== "TASK"
                    ? subChild.type === "QUIZ"
                      ? "Kuis"
                      : subChild.title
                    : "Tugas Praktek Mandiri",
                code: `chapter${sIndex + 1}-material${mIndex + 1}?t=${
                  data.title
                }`,
                isComplete:
                  userSheet?.data?.sessions[sIndex]?.subjects[mIndex]
                    ?.isComplete,
              })),
              // [
              //   {
              //     type: "material",
              //     title: "Testing materi",
              //     active: true,
              //   },
              //   {
              //     type: "quiz",
              //     title: "Testing kuis",
              //     active: false,
              //   },
              //   {
              //     type: "tpm",
              //     title: "Testing tpm",
              //     active: false,
              //   },
              // ],
            })),
            isCourses: true,
          },
          {
            title: "Test",
            child: [
              {
                title: "Post Test",
                icon: "list_alt",
                active: finishedAllSession(),
                code: `posttest?t=${data.title}`,
                hidden:!!withPostTest
              },
              {
                title: "Uji Keterampilan",
                icon: "playlist-add-check",
                active: user?.isAssesor || userSheet.data.score.postTest > 59,
                code: `uk?t=${data.title}`,
                hidden:!!withSkillTest

              },
            ],
            hidden : !!withPostTest && !!withSkillTest
          },
          {
            title: "Survey",
            child: [
              {
                title: "Reflective Journal",
                icon: "journal",
                active: true,
                code: `journal?t=${data.title}`,
              },
            ],
          },
          {
            title: "Sertifikat",
            child: [
              {
                title: "Unduh Sertifikat",
                icon: "certificate",
                active: true,
                code: `certificate?t=${data.title}`,
              },
            ],
          },
        ];
      case "WORKSHOP":
        return [
          {
            title: "Class Introduction",
            child: [
              {
                title: "Aturan Kelas",
                icon: "rule",
                active: true,
                code: `rules?t=${data.title}`,
              },
              {
                title: "Jadwal Kelas",
                icon: "calendar-outlined",
                active: true,
                code: `schedules?t=${data.title}`,
              },
              {
                title: "Pretest",
                icon: "list_alt",
                active: true,
                code: `pretest?t=${data.title}`,
                hidden : !!withPretest
              },
            ],
          },
          {
            title: "Sesi & materi kelas",
            child: data.sessions.map((c, sIndex) => ({
              title: c.title,
              icon: "folder",
              active: activeSessionWorkshop(sIndex) || user?.isAssesor,
              showned: pathname?.includes(`chapter${sIndex + 1}`)
                ? true
                : false,
              subChild: c?.subjects?.map((subChild, mIndex) => ({
                type: subChild.type,
                title:
                  subChild.type !== "TASK"
                    ? subChild.type === "QUIZ"
                      ? "Kuis"
                      : subChild.title
                    : "Tugas Praktek Mandiri",
                code: `chapter${sIndex + 1}-material${mIndex + 1}?t=${
                  data.title
                }`,
                isComplete:
                  userSheet?.data?.sessions[sIndex]?.subjects[mIndex]
                    ?.isComplete,
              })),
              // [
              //   {
              //     type: "material",
              //     title: "Testing materi",
              //     active: true,
              //   },
              //   {
              //     type: "quiz",
              //     title: "Testing kuis",
              //     active: false,
              //   },
              //   {
              //     type: "tpm",
              //     title: "Testing tpm",
              //     active: false,
              //   },
              // ],
            })),
            isCourses: true,
          },
          {
            title: "Test",
            child: [
              {
                title: "Post Test",
                icon: "list_alt",
                active: finishedAllSession(),
                code: `posttest?t=${data.title}`,
                hidden:!!withPostTest
              },
              {
                title: "Uji Keterampilan",
                icon: "playlist-add-check",
                active: user?.isAssesor || userSheet.data.score.postTest > 59,
                code: `uk?t=${data.title}`,
                hidden:!!withSkillTest

              },
            ],
            hidden : !!withPostTest && !!withSkillTest
          },
          {
            title: "Survey",
            child: [
              {
                title: "Reflective Journal",
                icon: "journal",
                active: true,
                code: `journal?t=${data.title}`,
              },
            ],
          },
          {
            title: "Sertifikat",
            child: [
              {
                title: "Unduh Sertifikat",
                icon: "certificate",
                active: true,
                code: `certificate?t=${data.title}`,
              },
            ],
          },
        ];
      case "WEBINAR":
        return [
          {
            title: "Class Introduction",
            child: [
              {
                title: "Aturan Kelas",
                icon: "rule",
                active: true,
                code: `rules?t=${data.title}`,
              },
              {
                title: "Jadwal Kelas",
                icon: "calendar-outlined",
                active: true,
                code: `schedules?t=${data.title}`,
              },
              {
                title: "Pretest",
                icon: "list_alt",
                active: true,
                code: `pretest?t=${data.title}`,
              },
            ],
          },
          {
            title: "Sesi & materi kelas",
            child: data.sessions.map((c, sIndex) => ({
              title: c.title,
              icon: "folder",
              active: activeSessionWebinar(sIndex),
              showned: pathname?.includes(`chapter${sIndex + 1}`)
                ? true
                : false,
              subChild: c?.subjects?.map((subChild, mIndex) => ({
                type: subChild.type,
                title:
                  subChild.type !== "TASK"
                    ? subChild.type === "QUIZ"
                      ? "Kuis"
                      : subChild.title
                    : "Tugas Praktek Mandiri",
                code: `chapter${sIndex + 1}-material${mIndex + 1}?t=${
                  data.title
                }`,
                isComplete:
                  userSheet?.data?.sessions[sIndex]?.subjects[mIndex]
                    ?.isComplete,
              })),

              // [
              //   {
              //     type: "material",
              //     title: "Testing materi",
              //     active: true,
              //   },
              //   {
              //     type: "quiz",
              //     title: "Testing kuis",
              //     active: false,
              //   },
              //   {
              //     type: "tpm",
              //     title: "Testing tpm",
              //     active: false,
              //   },
              // ],
            })),
            isCourses: true,
          },
          {
            title: "Test",
            child: [
              {
                title: "Post Test",
                icon: "list_alt",
                active: finishedAllSession(),
                code: `posttest?t=${data.title}`,
                hidden:!!withPostTest
              },
              {
                title: "Uji Keterampilan",
                icon: "playlist-add-check",
                active: user?.isAssesor || userSheet.data.score.postTest > 59,
                code: `uk?t=${data.title}`,
                hidden:!!withSkillTest

              },
            ],
            hidden : !!withPostTest && !!withSkillTest
          },
          {
            title: "Survey",
            child: [
              {
                title: "Reflective Journal",
                icon: "journal",
                active: true,
                code: `journal?t=${data.title}`,
              },
            ],
          },
          {
            title: "Sertifikat",
            child: [
              {
                title: "Unduh Sertifikat",
                icon: "certificate",
                active: true,
                code: `certificate?t=${data.title}`,
              },
            ],
          },
        ];
      default:
        return [
          {
            title: "Class Introduction",
            child: [
              {
                title: "Aturan Kelas",
                icon: "rule",
                active: true,
                code: `rules?t=${data.title}`,
              },
              {
                title: "Pretest",
                icon: "list_alt",
                active: true,
                code: `pretest?t=${data.title}`,
              },
            ],
          },
          {
            title: "Sesi & materi kelas",
            child: data.sessions.map((c, sIndex) => ({
              title: c.title,
              icon: "folder",
              active: activeSessionVideo(sIndex),
              showned: false,
              subChild: c?.subjects?.map((subChild, mIndex) => ({
                type: subChild.type,
                title:
                  subChild.type !== "TASK"
                    ? subChild.type === "QUIZ"
                      ? "Kuis"
                      : subChild.title
                    : "Tugas Praktek Mandiri",
                code: `chapter${sIndex + 1}-material${mIndex + 1}?t=${
                  data.title
                }`,
                isComplete:
                  userSheet?.data?.sessions[sIndex]?.subjects[mIndex]
                    ?.isComplete,
              })),

              // [
              //   {
              //     type: "material",
              //     title: "Testing materi",
              //     active: true,
              //   },
              //   {
              //     type: "quiz",
              //     title: "Testing kuis",
              //     active: false,
              //   },
              //   {
              //     type: "tpm",
              //     title: "Testing tpm",
              //     active: false,
              //   },
              // ],
            })),
            isCourses: true,
          },
          {
            title: "Test",
            child: [
              {
                title: "Post Test",
                icon: "list_alt",
                active: finishedAllSession(),
                code: `posttest?t=${data.title}`,
                hidden:!!withPostTest
              },
              {
                title: "Uji Keterampilan",
                icon: "playlist-add-check",
                active: user?.isAssesor || userSheet.data.score.postTest > 59,
                code: `uk?t=${data.title}`,
                hidden:!!withSkillTest

              },
            ],
            hidden : !!withPostTest && !!withSkillTest
          },
          {
            title: "Survey",
            child: [
              {
                title: "Reflective Journal",
                icon: "journal",
                active: true,
                code: `journal?t=${data.title}`,
              },
            ],
          },
          {
            title: "Sertifikat",
            child: [
              {
                title: "Unduh Sertifikat",
                icon: "certificate",
                active: true,
                code: `certificate?t=${data.title}`,
              },
            ],
          },
        ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSheet]);

  useEffect(() => {
    setSidebarData(defaultSideBar);
  }, [defaultSideBar]);

  const [showSidebar, setShowSidebar] = useState(true);

  const handleClickSessions = (index) => {
    const temp = [...sidebarData];
    if (temp[1]["child"][index]["active"]) {
      temp[1]["child"][index]["showned"] = !temp[1]["child"][index]["showned"];
    }
    setSidebarData(temp);
  };
  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && showSidebar && width <= 768) {
      setShowSidebar(false);
    }
  }, [boxOutsideClick, showSidebar, width]);

  const handleGenerateUrl = async (payload) => {
    try {
      localStorage.setItem("prakerjaPayload", JSON.stringify(payload));
      const res = await checkPrakerjaStatus(payload);
      if (res.data.attendance_status === 1) {
        setOpenModal(false);
      } else {
        localStorage.setItem("path", pathname);
        window.open(res.data.url);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (data.platform !== "OFFLINE") {
      if (chapterNumber || path === "posttest") {
        if (dataPrakerja) {
          setRedeemCode(dataPrakerja.redeem_code);
          checkPrakerjaStatus({
            ...dataPrakerja,
            sequence: path === "posttest" ? "999" : chapterNumber,
          })
            .then((res) => {
              if (res?.data?.attendance_status === 1) {
                setOpenModal(false);
              } else {
                setOpenModal(true);
              }
            })
            .catch((e) => {
              setOpenModal(true);
            });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterNumber, path]);

  return (
    <div className={Styles.container} ref={boxRef}>
      {width <= 768 && (
        <button
          className={Styles.showButton}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <Icon icon={showSidebar ? "cross" : "burger-icon"} size={20} />
        </button>
      )}
      <div className={Styles.sidebarContainer} is-showed={`${showSidebar}`}>
        <div className={Styles.logoSection}>
          <div className={Styles.logoWrapper} onClick={() => navigate("/")}>
            <img src={Images.LOGO} alt="logo" />
            <span>Skills.id</span>
          </div>

          {width <= 768 && (
            <button onClick={() => setShowSidebar(!showSidebar)}>
              <Icon icon={showSidebar ? "cross" : "burger-icon"} size={20} />
            </button>
          )}
        </div>
        <div className={Styles.progressSection}>
          <div className={Styles.progressWrapper}>
            <div className={Styles.progressComponent}>
              <div className={Styles.progressBase} />
              <div className={Styles.progress} style={{ width: progress }} />
            </div>
            <p className={Styles.progressText}>{progress}</p>
          </div>
          <div className={Styles.progressDesc}>
            <Icon icon={"book"} size={18} color={"#9360A8"} />
            <p>{`${
              userSheet?.progress?.subjectsFinishedTotal >
              userSheet?.progress?.subjectsTotal
                ? userSheet?.progress?.subjectsTotal
                : userSheet?.progress?.subjectsFinishedTotal
            } / ${userSheet?.progress?.subjectsTotal} Materi Selesai`}</p>
          </div>
        </div>
        <div className={Styles.mainSection}>
          {sidebarData.map((el, idx) => !el.hidden && (
            <div className={Styles.mainWrapper} key={idx}>
              <div>
                <Text color={"#616161"} width={"medium"} size={"s"}>
                  {el.title}
                </Text>
              </div>
              {el.isCourses ? (
                <div className={Styles.childWrapper}>
                  {el.child.map((child, cIndex) => (
                    <div key={cIndex}>
                      <div
                        className={Styles.child}
                        is-selected={`${child.showned}`}
                        is-active={`${child.active}`}
                        onClick={() => handleClickSessions(cIndex)}
                      >
                        <Icon
                          icon={child.showned ? "open-folder" : "folder"}
                          size={18}
                        />
                        <p className={Styles.sessionsTitle}>{`Sesi ${
                          cIndex + 1
                        }: ${child.title}`}</p>
                        {!child.active ? (
                          <Icon
                            icon={"info-circle-2"}
                            size={16}
                            className={Styles.tooltip}
                          />
                        ) : (
                          <Icon
                            icon={"keyboard_arrow_down"}
                            size={16}
                            className={Styles.tooltip}
                            is-rotate={`${child.showned}`}
                          />
                        )}
                      </div>

                      <div
                        className={Styles.subChildWrapper}
                        is-showned={`${child.showned}`}
                      >
                        {child.subChild.map((subChild, sIndex) => (
                          <Link
                            className={Styles.subChild}
                            key={sIndex}
                            is-active={`${
                              materialCode === subChild.code.split("?")[0]
                            }`}
                            to={subChild.code}
                            onClick={() => {
                              width <= 768 && setShowSidebar(false);
                            }}
                          >
                            <Icon
                              icon={
                                subChild.type === "MATTER"
                                  ? "video"
                                  : subChild.type === "QUIZ"
                                  ? "playlist-add-check"
                                  : "book_2"
                              }
                              size={18}
                            />
                            <div className={Styles.subChildTitle}>
                              <p>{subChild.title}</p>
                            </div>
                            {subChild.isComplete && (
                              <div className={Styles.subChildSuccessIcon}>
                                <Icon
                                  icon={"check-circle"}
                                  size={16}
                                  color={"#5D5CBD"}
                                />
                              </div>
                            )}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={Styles.childWrapper}>
                  {el.child.map((child, cIndex) => !child.hidden && (
                    <Link
                      key={cIndex}
                      className={Styles.child}
                      is-selected={`${child.code.split("?")[0] === path}`}
                      is-active={`${child.active}`}
                      to={child.active ? child.code : "#"}
                      onClick={() => {
                        width <= 768 && setShowSidebar(false);
                      }}
                    >
                      <Icon icon={child.icon} size={18} />

                      <p>{child.title}</p>
                      {!child.active && (
                        <Icon
                          icon={"info-circle-2"}
                          size={16}
                          className={Styles.tooltip}
                        />
                      )}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        {width >= 768 && (
          <div
            className={Styles.chevron}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <Icon
              icon={"chevron-right"}
              size={16}
              color={"#26255B"}
              is-rotate={`${showSidebar}`}
              className={Styles.chevronIcon}
            />
          </div>
        )}
      </div>
      {width >= 768 && (
        <div className={Styles.margin} is-showed={`${showSidebar}`} />
      )}
      <ReedemCodeModal
        isOpen={openModal}
        disabledClose
        value={redeemCode}
        onChange={(e) => setRedeemCode(e.target.value)}
        onSubmit={() =>
          handleGenerateUrl({
            sequence: path === "posttest" ? "999" : chapterNumber,
            redeem_code: redeemCode,
            email: user.email,
            courseID: data._id,
          })
        }
      />
    </div>
  );
}
