/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import Styles from "./style.module.scss";
import skillsLogo from "@Assets/Icon/skillsLogoWhite.svg";
import Images from "@Theme/Images";
import Button from "@Atom/Button";
import useQuery from "@Hooks/useQuery";
import { _checkoutStatus, _checkoutStudentClass } from "@Services/transaction";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { decryptStorageData } from "@Helpers/encryptDecrypt";

export default function CheckoutCallback() {
  const query = useQuery();
  const invoiceCode = query.get("token");
  const [status, setStatus] = useState(1);
  const navigate = useNavigate();
  const user = decryptStorageData("user", localStorage);

  const handleCheckStatus = async () => {
    try {
      const { data } = await _checkoutStatus({ invoiceCode });
      if (data.status === "PENDING") {
        setStatus(1);
      }
      if (data.status === "PAID" || data.status === "SUCCESS") {
        setStatus(2);
      }
    } catch (error) {
      setStatus(1);
    }
  };
  const handleCheckout = async () => {
    try {
      await _checkoutStudentClass({ invoiceCode });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      handleCheckStatus();
    } else {
      navigate(`/login?c=/checkout-status?token=${invoiceCode}`)
    }
  }, []);
  useEffect(() => {
    if (status === 2) {
      handleCheckout();
    }
  }, [status]);

  const navigateTo = useMemo(() => {
    if (status === 1) {
      return "/student/transaction";
    } else {
      return "/student";
    }
  }, [status]);
  const handleNavigate = () => {
    if (user) {
      navigate(navigateTo);
    } else {
      navigate(`/login?c=${navigateTo}`);
    }
  };

  return (
    <div className={Styles.body}>
      <div className={Styles.container}>
        <div className={Styles.logoWrapper}>
          <img src={skillsLogo} alt="" />
          <p>Skills.id</p>
        </div>
        <div className={Styles.bodyWrapper}>
          <img
            src={status === 1 ? Images.LOADING_CLOCK : Images.SUCCESS_SHIELD}
            alt=""
          />
          <div className={Styles.bodyDesc}>
            <p>
              {status === 1 ? "Menunggu Pembayaran" : "Pembayaran Berhasil"}
            </p>
            <p>
              {status === 1
                ? "Silahkan lanjutkan pembayaran kamu, untuk melihat daftar pembayaranmu silahkan lihat pada halaman transaksi di dashboard kamu"
                : "Pembayaran kamu telah selesai, Silahkan akses kelasmu pada menu daftar kelas di halaman dashboard"}
            </p>
          </div>
        </div>
        <div className={Styles.buttonWrapper}>
          <Button
            variant="neutral-contained"
            text={
              status === 1 ? "Lihat Daftar Transaksi" : "Akses Kelas Sekarang"
            }
            onClick={() => handleNavigate()}
          />
        </div>
      </div>
    </div>
  );
}
