import React from "react";
import Styles from "./style.module.scss";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";
import Footer from "@Molecule/Footer";
import Button from "@Atom/Button";
import moment from "moment";

export default function TransacionDetailLayout({ data, isLoading }) {
  if (isLoading) {
    return (
      <div className={Styles.loadingContainer}>
        <Lottie animationData={loadingClass} loop={true} />
        <p>Memuat Kontent</p>
      </div>
    );
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <div className={Styles.header}>
          <Button text={"Kembali"} variant="text" startIcon={"chevron-left"} />
          <div className={Styles.status} status={data.status}>
            <p>
              {data.status === "PAID-OFF"
                ? "Transaksi Berhasil"
                : "Transaksi Tidak Berhasil"}
            </p>
          </div>
        </div>
        <div className={Styles.topSection}>
          <div>
            <p>{data.invoiceCode}</p>
            {data.status === "PAID-OFF" && (
              <React.Fragment>
                <p>•</p>
                <p>
                  terbayar pada : {moment(data.paidDate).format("DD MMMM YYYY")}
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className={Styles.mainSection}>
          <div className={Styles.left}>
            <div className={Styles.card}>
              <div className={Styles.cardContent}>
                <p>Detail Pembeli</p>
                <div className={Styles.label}>
                  <p>Name</p>
                  <p>{data.user.name}</p>
                </div>
                <div className={Styles.label}>
                  <p>Email</p>
                  <p>{data.user.email}</p>
                </div>
                <div className={Styles.label}>
                  <p>No Telp</p>
                  <p>{data.user.phone}</p>
                </div>
              </div>
              <div className={Styles.divider} />
              <div className={Styles.cardContent}>
                <p>Detail Pembelian</p>
                <div className={Styles.label}>
                  <p>{data.courseTitle}</p>
                  <p>Rp{(+data.price.base).toLocaleString("id")}</p>
                </div>
                <div className={Styles.discount}>
                  <p>
                    Discount <span>({data.price.discount})</span>
                  </p>
                  <p>
                    - Rp
                    {+data.price.base *
                      (+data.price.discount.split("%").join("") / 100)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.right}>
            <div className={Styles.card}>
              <p>Rincian</p>
              <div className={Styles.cardContent}>
                <div className={Styles.label}>
                  <p>
                    SubTotal <span>(1 Kelas)</span>
                  </p>
                  <p>Rp{(+data.price.base).toLocaleString("id")}</p>
                </div>
                <div className={Styles.discount}>
                  <p>
                    Discount <span>({data.price.discount})</span>
                  </p>
                  <p>
                    - Rp
                    {+data.price.base *
                      (+data.price.discount.split("%").join("") / 100)}
                  </p>
                </div>
              </div>
              <div className={Styles.divider} />
              <div>
                <p>Total Bayar</p>
                <p>{data.price.actual}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
