import React, { useCallback, useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import TransparentSearchbar from "@Atom/TransparentSearchbar";
import Images from "@Theme/Images";
import TabHeader from "@Atom/TabHeader";
import iconPrakerja from "@Assets/Images/prakerja.png";
import Icon from "@Atom/Icon";
import Footer from "@Molecule/Footer";
import { fileBaseUrl } from "@Config/index";
import { useMemo } from "react";
import NotFound from "@Molecule/NotFound";
import { useNavigate } from "react-router-dom";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import ReedemCodeModal from "@Molecule/ReedemCodeModal";
import { checkPrakerjaStatus } from "@Services/prakerja";
import Lottie from "lottie-react";
import loadingClass from "@Assets/animation/loadingClass.json";

export default function MyClass({
  isLocked,
  onClick = () => {},
  isPrakerja = true,
  data = [],
  isLoading,
}) {
  const [tabs, setTabs] = useState(1);
  const tabOptions = ["Kelas Aktif", "Kelas Selesai"];
  const [isErrorImage, setIsErrorImage] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [selectedClass, setSelectedClass] = useState("");
  const [redeemCode, setRedeemCode] = useState("");
  const [errorRedeem, setErrorRedeem] = useState("");
  const [loadingRedeem, setLoadingRedeem] = useState(false);
  const searchClass = useCallback(() => {
    if (tabs === 2) {
      return data.filter(
        (item) =>
          item.progress.subjectsFinishedTotal === item.progress.subjectsTotal &&
          item.course.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (tabs === 1) {
      return data.filter(
        (item) =>
          item.progress.subjectsFinishedTotal < item.progress.subjectsTotal &&
          item.course.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  }, [data, searchQuery, tabs]);

  const dataToShow = useMemo(() => {
    let filteredData;
    if (tabs === 1) {
      filteredData = data.filter(
        (item) =>
          item.progress.subjectsFinishedTotal < item.progress.subjectsTotal
      );
    } else {
      filteredData = data.filter(
        (item) =>
          item.progress.subjectsFinishedTotal >= item.progress.subjectsTotal
      );
    }
    if (searchQuery && searchQuery.trim() !== "") {
      filteredData = searchClass();
    }
    return filteredData;
  }, [tabs, searchQuery, data, searchClass]);
  const isNull = dataToShow.length === 0 || data.length === 0;

  const user = decryptStorageData("user", localStorage);

  const handleGenerateUrl = async () => {
    const payload = {
      email: user.email,
      redeem_code: redeemCode,
      sequence: "1",
      courseID: selectedClass.courseID,
    };
    try {
      setLoadingRedeem(true);
      const res = await checkPrakerjaStatus(payload);
      if (res.data.attendance_status === 1) {
        navigate(
          `/classes/learn/${selectedClass.courseID}/rules?t=${selectedClass.title}`
        );
      } else {
        window.open(res.data.url);
      }
      setLoadingRedeem(false);
    } catch (error) {
      setLoadingRedeem(false);

      setErrorRedeem(
        error.response.data?.message || "Kode Redeem Tidak Ditemukan"
      );
    }
  };

  const countPercentage = (progress) => {
    let percentage = Math.round(
      (progress.subjectsFinishedTotal / progress.subjectsTotal) * 100
    );
    if (percentage > 100) {
      percentage = 100;
    }
    return percentage;
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.layout}>
        <section className={Styles.doodle}>
          <DoodleGradientCard>
            <div className={Styles.topCard}>
              <div className={Styles.leftSection}>
                <div>
                  <p className={Styles.title}>
                    Selamat Datang <span>{user?.name}</span>
                  </p>
                  <p className={Styles.desc}>
                    Berikut daftar kelasmu, mulai dan selesaikan belajarmu
                  </p>
                </div>
                <TransparentSearchbar
                  placeholder="Cari Kelasmu"
                  setValue={setSearchQuery}
                  value={searchQuery}
                />
              </div>
              <div className={Styles.rightSection}>
                <img
                  src={Images.STUDENT_DOODLE_ICON}
                  width={"168px"}
                  height={"168px"}
                  alt="icon"
                />
              </div>
            </div>
          </DoodleGradientCard>
        </section>
        <section className={Styles.tabsHeader}>
          <TabHeader tabs={tabs} setTabs={setTabs} tabOptions={tabOptions} />
          {isLoading ? (
            <div className={Styles.loadingContainer}>
              <Lottie animationData={loadingClass} loop={true} />
              <p>Memuat Kontent</p>
            </div>
          ) : (
            <div className={isNull ? Styles.notFound : Styles.wrapperCard}>
              {isNull ? (
                <NotFound
                  isStudent={true}
                  onClick={() => navigate("/courses")}
                />
              ) : (
                <>
                  {dataToShow.map((item, idx) => (
                    <div className={Styles.card} key={idx}>
                      <img
                        src={
                          isErrorImage
                            ? "https://placehold.co/306x250"
                            : item.course.asset.thumbnail.imageUrl.includes(
                                "https"
                              )
                            ? item.course.asset.thumbnail.imageUrl
                            : fileBaseUrl + item.course.asset.thumbnail.imageUrl
                        }
                        className={Styles.images}
                        alt="images"
                        onError={() => setIsErrorImage(true)}
                      />
                      <div className={Styles.describeClass}>
                        <div className={Styles.percentage}>
                          <div
                            percentage={countPercentage(item.progress)}
                            isLocked={isLocked ? "true" : "false"}
                            className={Styles.indicator}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="53"
                              height="53"
                              viewBox="0 0 53 54"
                              fill="none"
                            >
                              <circle
                                cx="26.5"
                                cy="27"
                                r="25"
                                stroke="white"
                                strokeWidth="4"
                                fill="none"
                              />
                              <circle
                                cx="26.5"
                                cy="27"
                                r="25"
                                stroke="#c2d6fc"
                                strokeWidth="4"
                                fill="none"
                                strokeDasharray="157 157"
                                strokeDashoffset={
                                  157 *
                                  (1 -
                                    (isLocked
                                      ? 0
                                      : countPercentage(item.progress)) /
                                      100)
                                }
                                transform="rotate(-90 26.5 27)"
                              />
                            </svg>
                          </div>
                          <div>
                            <p>
                              {isLocked
                                ? "0 / 20"
                                : `${
                                    item.progress.subjectsFinishedTotal >
                                    item.progress.subjectsTotal
                                      ? item.progress.subjectsTotal
                                      : item.progress.subjectsFinishedTotal
                                  } / ${item.progress.subjectsTotal}`}
                            </p>
                            <p>Materi Selesai</p>
                          </div>
                        </div>
                        <div className={Styles.classDescription}>
                          <div className={Styles.text}>
                            <div className={Styles.typeClass}>
                              <div>
                                <span>{item.course.level}</span>
                              </div>
                              <div>
                                <span>{item.course.platform}</span>
                              </div>
                              {item.course.type === "PRAKERJA" && (
                                <img
                                  src={iconPrakerja}
                                  alt="prakerja"
                                  width={"43.269px"}
                                  height={"13.823px"}
                                />
                              )}
                            </div>
                            <div className={Styles.textEllipsis}>
                              <p>{item.course.title}</p>
                            </div>
                            <p>{item.course.instructor.name}</p>
                          </div>
                          <div
                            className={Styles.buttonCard}
                            isLocked={
                              (item.progress.subjectsFinishedTotal /
                                item.progress.subjectsTotal) *
                                100 ===
                              0
                                ? "true"
                                : "false"
                            }
                            onClick={
                              (item.progress.subjectsFinishedTotal /
                                item.progress.subjectsTotal) *
                                100 ===
                                0 &&
                              item.course.platform !== "OFFLINE" &&
                              !user.isAssesor &&
                              item.course.type === "PRAKERJA"
                                ? () => {
                                    setSelectedClass(item.course);
                                  }
                                : () =>
                                    navigate(
                                      `/classes/learn/${item.course.courseID}/rules?t=${item.course.title}`
                                    )
                            }
                          >
                            {(item.progress.subjectsFinishedTotal /
                              item.progress.subjectsTotal) *
                              100 ===
                            0 ? (
                              <div className={Styles.lockedClass}>
                                <Icon icon={"lock_outline"} size={"16"} />
                                <span>Mulai Belajar</span>
                              </div>
                            ) : item.progress.subjectsFinishedTotal ===
                              item.progress.subjectsTotal ? (
                              <span> Lihat Kelas</span>
                            ) : (
                              <span> Lanjutkan Belajar</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </section>
      </div>
      <ReedemCodeModal
        isOpen={!!selectedClass}
        onClose={() => {
          setSelectedClass("");
          setErrorRedeem("");
        }}
        isError={errorRedeem}
        setIsError={setErrorRedeem}
        value={redeemCode}
        onChange={(e) => setRedeemCode(e.target.value)}
        onSubmit={handleGenerateUrl}
        loading={loadingRedeem}
      />
      <Footer />
    </div>
  );
}
