import { useCallback } from "react";
import Styles from "./style.module.scss";
import InputField from "@Molecule/InputField";
import Switch from "@Atom/Switch";

export default function SkillTestSection({
  form,
  setForm,
  config,
  setConfig = () => {},
  participantType,
}) {
  const handleChangeForm = useCallback(
    (name, newVal, childName, id) => {
      if (!childName) {
        setForm((prev) => {
          return {
            ...prev,
            [name]: newVal,
          };
        });
      } else {
        setForm((prev) => {
          return {
            ...prev,
            [name]: prev[name]?.map((obj) => {
              if (obj?.id === id) {
                return {
                  ...obj,
                  [childName]: newVal,
                };
              } else {
                return obj;
              }
            }),
          };
        });
      }
    },
    [setForm]
  );

  const onChangeConfig = (name, value) => {
    let temp = { ...config };
    temp[name] = value;
    setConfig(temp);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Uji Keterampilan</h3>
        <span>
          Unggah panduan untuk menjelaskan bagaimana cara dan tujuan peserta
          mengikuti Uji Keterampilan kelas Anda
        </span>
      </div>
      {participantType !== "PRAKERJA" && (
        <div className={Styles.configWrapper}>
          <div className={Styles.config}>
            <p>Aktifkan Uji Keterampilan untuk kelas ini</p>
            <Switch
              isChecked={config.skillTest}
              setIsChecked={() => onChangeConfig("skillTest", !config.skillTest)}
            />
          </div>
        </div>
      )}
      <div className={Styles.content} is-shown={String(config.skillTest)}>
        <InputField
          title={"Panduan Uji Keterampilan"}
          value={form?.guide}
          setValue={(newVal) => handleChangeForm("guide", newVal)}
          isFile
          isPDFOnly
          speed={30}
          placeholder="Pilih file untuk panduan uji keterampilan"
          remark="Hanya mendukung file .jpg, .jpeg, .Mp4, dan .pdf"
          required
        />
        <InputField
          title={"Deskripsi"}
          value={form?.description}
          setValue={(newVal) => handleChangeForm("description", newVal)}
          placeholder="Deskripsi panduan"
          required
        />
      </div>
    </div>
  );
}
