import { API } from "@Config/index";

export function _checkoutClass(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post("/transaction/checkout", payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function _checkoutStatus(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post("/transaction/checkout-status", payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function _checkoutStudentClass(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post("transaction/user", payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function _fetchStudentTransaction() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get("/transaction", {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function _fetchTransactionById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/transaction/${id}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
