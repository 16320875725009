import Transaction from "@Organism/StudentDashboard/Transaction";
import { _fetchStudentTransaction } from "@Services/transaction";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export function loader() {
  const getAllTransaction = _fetchStudentTransaction();

  return defer({
    getAllTransaction,
  });
}

export default function StudentDashboardTransaction() {
  const { getAllTransaction } = useLoaderData();

  return (
    <Suspense fallback={<Transaction datas={[]} isLoading />}>
      <Await
        resolve={getAllTransaction}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <Transaction datas={value.data} />}
      </Await>
    </Suspense>
  );
}
